<template>
  <div class="study-list">
	  
	  <MyUpdata ref="MyUpdata"   />
    <!-- <el-card>
      <el-form v-model="form" label-width="100">
        <el-form-item label="您已选择">
          <el-tag
              :key="tag"
              v-for="tag in tags"
              closable
              :disable-transitions="false"
              @close="handleClose(tag)">
            {{tag}}
          </el-tag>
        </el-form-item>

        <el-form-item label="更多选择">
          <el-select :value="form.name" size="mini" placeholder="教材书（资料）名称">
            <el-option label="" value=""></el-option>
          </el-select>
          <el-select :value="form.name" size="mini" placeholder="第几版" style="width: 120px;">
            <el-option label="" value=""></el-option>
          </el-select>
          <el-select :value="form.name" size="mini" placeholder="封面标志性说明，适用人员，适用范围" style="width: 260px;">
            <el-option label="" value=""></el-option>
          </el-select>
          <el-select :value="form.name" size="mini" placeholder="题目、章节名称" style="width: 140px;">
            <el-option label="" value=""></el-option>
          </el-select>
          <el-select :value="form.name" size="mini" placeholder="主编" style="width: 100px;">
            <el-option label="" value=""></el-option>
          </el-select>
          <el-select :value="form.name" size="mini" placeholder="副主编" style="width: 100px;">
            <el-option label="" value=""></el-option>
          </el-select>
          <el-select :value="form.name" size="mini" placeholder="出版发行(社)" style="width: 140px;">
            <el-option label="" value=""></el-option>
          </el-select>
          <el-select :value="form.name" size="mini" placeholder="出版日期" style="width: 120px;">
            <el-option label="" value=""></el-option>
          </el-select>
          <el-select :value="form.name" size="mini" placeholder="图片制作上传者姓名" style="width: 200px;">
            <el-option label="" value=""></el-option>
          </el-select>
          <el-select :value="form.name" size="mini" placeholder="图片制作上传时间" style="width: 200px;">
            <el-option label="" value=""></el-option>
          </el-select>
          <el-select :value="form.name" size="mini" placeholder="图片制作上传者所属单位（首选）或曾履职单位，学习、毕业学校" style="width: 400px;">
            <el-option label="" value=""></el-option>
          </el-select>
          <el-select :value="form.name" size="mini" placeholder="适用于哪年哪月测试观看" style="width: 200px;">
            <el-option label="" value=""></el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </el-card> -->

    <el-card class="margin-top padding-bottom">
      <el-table style="width: 100%;" :data="rows" @row-click="ups">
        <el-table-column
            type="index"
            label="序号"
            width="50">
        </el-table-column>
		 
		
		
        <el-table-column
            prop="title"
            label="标题"
            width="120">
        </el-table-column>
		
		<el-table-column
		    prop="create_time"
		    label="上传时间"
		     >
		</el-table-column>
		
       <el-table-column
            prop="dcesc"
            label="操作">
			<button  > 查看 </button>
        </el-table-column>  
		
      </el-table>

      <!-- <el-pagination
          background
          layout="prev, pager, next"
          :total="1000">
      </el-pagination> -->
	  
	  
	  
    </el-card>
	
	
	
  </div>
  
</template>

<script>
	import axios from "axios";
  import MyUpdata from '../../components/MyUpdataLookTitle.vue'
  export default {
    name: "StudyList",
    components: {  MyUpdata },
    data() {
      return {
        tags: [
          '标签一', '标签二', '标签三'
        ],
        form: {},
        rows: []
      }
    },
	
 
	
	
	created(){  
		const params = new URLSearchParams();
		params.append('uid', sessionStorage.getItem('uid')); 
		params.append('type', 1);
		console.log(params);
		axios.post(this.apiUrl+'/api/user/getUpdateDatas',params)
		.then((response) => {   
			this.rows = response.data.data; 
					  
		}) 
		.catch(function(error) {
		  console.log(error);
		});
	},
	
	
    methods: {
		ups(e) { 
		  this.$refs.MyUpdata.open(e.id)
		},
      handleClose(tag) {
        this.tags.splice(this.tags.indexOf(tag), 1);
      },

      /**
       * 打开某个页面
       * @param row
       */
      openPage(row) {
        console.log(row);
        let routeData = this.$router.resolve({
          name : '教材书籍'
        })
        console.log(routeData.href);
        window.open(routeData.href, "_blank")
      }
    }
  }
</script>

<style scoped lang="scss">

  .margin-top {
    margin-top: 20px;
  }

  .study-list {
    .el-card {
      /deep/ .el-card__body {
        padding-bottom: 0;
      }

      &.padding-bottom {
        padding-bottom: 20px;
      }
    }

    /deep/ .el-form-item__label {
      font-weight: bold;
    }

    /deep/ .el-form-item__content {
      margin-left: 80px;
    }

    .el-select {
      margin-right: 10px;
      margin-bottom: 8px;
    }

    .el-tag {
      color: $green;
      background-color: #ffffff;
      border-color: $green;
      margin-right: 10px;

      /deep/ .el-tag__close {
        color: $green;

        &:hover {
          color: #ffffff;
          background-color: $green;
        }
      }
    }

    .el-image:hover {
      cursor: pointer;
    }

    .el-pagination {
      text-align: center;
      margin-top: 20px;
    }
  }

</style>
